import { navigate, PageProps } from "gatsby"
import { parse } from "query-string"
import { ProgressIndicator } from "@dnb/eufemia/components"
import React, { useState, useContext, useEffect } from "react"
import ErrorModal from "./ErrorModal"
import AuthContext from "../auth/context"
import { useQuery, useQueryClient } from "react-query"
import { fetchCurrentUser, User } from "../api/User"

const AuthRedirect = ({ location }: PageProps) => {
  const { user_ } = useContext(AuthContext)

  const [error, setError] = useState(``)
  const [error_, setError_] = useState(true)
  const [name, setName] = useState(null)

  const queryClient = useQueryClient()
  const user = useQuery<User, Error>(`user`, () => fetchCurrentUser(), { retry: false })

  useEffect(() => {
    const error = parse(location.hash.replace(/^#/, ``))
    if (Object.keys(error).length > 0) {
      setError_(true)
    } else {
      if (user?.isError) {
        setError_(true)
      } else if (user?.isSuccess) {
        localStorage.setItem(`user_name`, user?.data?.name)
        navigate(`/table/`)
        setError_(false)
      }
    }
  }, [user])

  return (
    <>
      {user?.isError ? (
        <ErrorModal error={user?.error} />
      ) : (
        <div style={{ margin: `16rem auto`, width: `4rem` }}>
          <ProgressIndicator size="large" />
        </div>
      )}
    </>
  )
}

export default AuthRedirect
