import { PageProps } from "gatsby"
import React from "react"

import AuthRedirect from "../components/AuthRedirect"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()

function LoginCompletePage(props: PageProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthRedirect {...props} />
    </QueryClientProvider>
  )
}

export default LoginCompletePage
