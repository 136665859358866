import { request } from "./index"

export type User = {
  ssn: string
  expires: number
  name: string
}

export async function fetchCurrentUser(): Promise<User> {
  return request<User>({
    method: `GET`,
    endpoint: `User`,
  })
}
